<template>
  <div>
    <div class="tableresponsive" style="z-index: 1020 !important">
      <div class="row mb-3">
        <div class="col-12 col-md-6">
          <div class="d-flex">
            <div class="my-3">
              <span class="h4 text-secondary mr-auto my-auto"
                >Liste des dossiers inscrits
              </span>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-6 d-flex justify-content-end align-btn-table-2"
        >
          <custom-add-button
            class="btn h1 mb-2 btn-secondary d-flex align-items-center"
            variant="secondary"
            text=" Nouvelle inscription"
            :disabled="
              disabled || (!isAdmin && !isSuper && !isManager && !isGouvernance)
            "
            @click="addContributerInputs"
          ></custom-add-button>
        </div>
        <div class="col-12">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
      </div>
      <!-- {{ items }} -->
      <b-table
        :items="items"
        :session="session"
        :fields="fields"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        show-empty
        bordered
        striped
      >
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <template v-slot:cell(livrable)="data">
          <span
            class="d-flex"
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          >
            <a
              v-if="data.value.fileUrl"
              class="text-truncate"
              style="max-width: 100px"
              :href="IMG_URL + data.value.fileUrl"
              target="_blank"
              >{{ data.value.filename }}</a
            >
            <span v-else> -</span>
          </span>

          <div v-else>
            <b-form-file
              :disabled="data.item.id && !isAdmin && !isSuper && !isGouvernance"
              style="width: 250px !important"
              placeholder="Choissez un fichier"
              @change="handleFileUpload($event)"
            ></b-form-file>
          </div>
        </template>

        <template v-slot:cell(type)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
            >{{ data.value ? data.value : "-" }}</span
          >
          <div v-else style="width: 150px !important">
            <v-select
              @input="changedossier"
              :disabled="
                data.item.id &&
                !isAdmin &&
                !isSuper &&
                !isManager &&
                !isGouvernance
              "
              append-to-body
              :options="[
                'FdR',
                'Initiative',
                'Cadre',
                'Composant technique',
                'Application',
                'Dossier libre',
              ]"
              v-model="newInscriprion.type"
              placeholder="Type dossier"
              style="padding-right: 0 !important"
              :input-attr="{
                class: {
                  'is-invalid':
                    $v.newInscriprion.type.$error &&
                    $v.newInscriprion.type.$dirty,
                  ' form-control': true,
                },
              }"
              :class="{
                'is-invalid form-control p-0':
                  $v.newInscriprion.type.$error &&
                  $v.newInscriprion.type.$dirty,
              }"
            >
              <template #no-options> Liste vide</template>
            </v-select>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInscriprion.type.$dirty"
            >
              {{ !$v.newInscriprion.type.required ? "Champ obligatoire" : "" }}
            </span>
          </div>
        </template>

        <template v-slot:cell(objetPassage)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
            >{{ data.value ? data.value : "-" }}</span
          >
          <div v-else style="width: 150px !important">
            <v-select
              append-to-body
              :disabled="
                data.item.id &&
                !isAdmin &&
                !isSuper &&
                !isManager &&
                !isGouvernance
              "
              :options="['Avis', 'Decision', 'Information']"
              v-model="newInscriprion.objetPassage"
              placeholder="objet Passage"
              style="padding-right: 0 !important"
              :input-attr="{
                class: {
                  'is-invalid':
                    $v.newInscriprion.objetPassage.$error &&
                    $v.newInscriprion.objetPassage.$dirty,
                  ' form-control': true,
                },
              }"
              :class="{
                'is-invalid form-control p-0':
                  $v.newInscriprion.objetPassage.$error &&
                  $v.newInscriprion.objetPassage.$dirty,
              }"
            >
              <template #no-options> Liste vide</template>
            </v-select>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInscriprion.objetPassage.$dirty"
            >
              {{
                !$v.newInscriprion.objetPassage.required
                  ? "Champ obligatoire"
                  : ""
              }}
            </span>
          </div>
        </template>

        <template v-slot:cell(createdAt)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          >
            {{
              data.value
                ? moment(data.value, "DD/MM/YYYY").format("DD/MM/YYYY")
                : "-"
            }}</span
          >
          <div v-else style="width: 150px !important">
            <date-picker
              disabled
              v-model="newInscriprion.date"
              style="padding-right: 0 !important"
              format="DD/MM/YYYY"
            ></date-picker>
          </div>
        </template>

        <template v-slot:cell(status)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
            >{{ data.value ? data.value : "-" }}</span
          >
          <div v-else style="width: 150px !important">
            <v-select
              :disabled="data.item.id && !isAdmin && !isSuper && !isGouvernance"
              append-to-body
              :options="[
                'Demande',
                'Validé',
                'Non validé',
                'Présenté',
                'Non présenté',
              ]"
              v-model="newInscriprion.status"
              placeholder="Statut"
              style="padding-right: 0 !important"
              :input-attr="{
                class: {
                  'is-invalid':
                    $v.newInscriprion.status.$error &&
                    $v.newInscriprion.status.$dirty,
                  ' form-control': true,
                },
              }"
              :class="{
                'is-invalid form-control p-0':
                  $v.newInscriprion.status.$error &&
                  $v.newInscriprion.status.$dirty,
              }"
            >
              <template #no-options> Liste vide</template>
            </v-select>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInscriprion.status.$dirty"
            >
              {{
                !$v.newInscriprion.status.required ? "Champ obligatoire" : ""
              }}
            </span>
          </div>
        </template>

        <template v-slot:cell(rangPassage)="data">
          <div
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          >
            <span>{{ data.value }}</span>
          </div>
          <div v-else style="width: 150px !important">
            <v-select
              type="number"
              v-model="newInscriprion.rangPassage"
              min="0"
              :disabled="
                data.item.id &&
                !isAdmin &&
                !isSuper &&
                !isManager &&
                !isGouvernance
              "
              :options="rangePassagesList"
              appendToBody
              style="min-width: max-content; padding-right: 0 !important"
              placeholder="Rang passage"
              :class="{
                'is-invalid form-control p-0':
                  $v.newInscriprion.rangPassage.$error &&
                  $v.newInscriprion.rangPassage.$dirty,
              }"
            ></v-select>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInscriprion.rangPassage.$dirty"
            >
              {{
                !$v.newInscriprion.rangPassage.required
                  ? "Champ obligatoire"
                  : ""
              }}
            </span>
          </div>
        </template>

        <template v-slot:cell(temps)="data">
          <div
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          >
            <span>{{ data.value ? data.value : "-" }}</span>
          </div>
          <div v-else style="width: 150px !important">
            <v-select
              :options="[
                '0min',
                '15min',
                '30min',
                '45min',
                '1h',
                '1h15min',
                '1h30min',
                '1h45min',
                '2h',
                '2h15min',
                '2h30min',
                '2h45min',
                '3h',
              ]"
              :disabled="data.item.id && !isAdmin && !isSuper && !isGouvernance"
              append-to-body
              v-model="newInscriprion.temps"
              placeholder="Temps"
              style="padding-right: 0 !important"
              :input-attr="{
                class: {
                  'is-invalid':
                    $v.newInscriprion.temps.$error &&
                    $v.newInscriprion.temps.$dirty,
                  ' form-control': true,
                },
              }"
              :class="{
                'is-invalid form-control p-0':
                  $v.newInscriprion.temps.$error &&
                  $v.newInscriprion.temps.$dirty,
              }"
            >
              <template #no-options> Liste vide</template>
            </v-select>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInscriprion.temps.$dirty"
            >
              {{ !$v.newInscriprion.temps.required ? "Champ obligatoire" : "" }}
            </span>
          </div>
        </template>

        <template v-slot:cell(user)="data">
          <div
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
          >
            <TableResponsable :responsable="data.value" />
          </div>
          <div v-else style="width: 150px">
            <v-select
              :disabled="
                data.item.id &&
                !isAdmin &&
                !isSuper &&
                !isManager &&
                !isGouvernance
              "
              append-to-body
              label="text"
              :reduce="(respo) => respo.value"
              :options="responsables"
              v-model="newInscriprion.selectedResponsable"
              placeholder="Présentateur"
              style="padding-right: 0 !important"
              :input-attr="{
                class: {
                  'is-invalid':
                    $v.newInscriprion.selectedResponsable.$error &&
                    $v.newInscriprion.selectedResponsable.$dirty,
                  ' form-control': true,
                },
              }"
              :class="{
                'is-invalid form-control p-0':
                  $v.newInscriprion.selectedResponsable.$error &&
                  $v.newInscriprion.selectedResponsable.$dirty,
              }"
            >
              <template #no-options> Liste vide </template>
            </v-select>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInscriprion.selectedResponsable.$dirty"
            >
              {{
                !$v.newInscriprion.selectedResponsable.required
                  ? "Champ obligatoire"
                  : ""
              }}
            </span>
          </div>
        </template>
        <template v-slot:cell(dossier)="data">
          <div>
            <span
              v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
              >{{ data.value ? data.value : "-" }}</span
            >
            <div v-else style="width: 150px">
              <div v-if="newInscriprion.type == 'Dossier libre'">
                <b-form-input
                  v-model="newInscriprion.selectedDossier"
                  placeholder="Dossier"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.newInscriprion.selectedDossier.$error &&
                        $v.newInscriprion.selectedDossier.$dirty,
                      ' form-control': true,
                    },
                  }"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newInscriprion.selectedDossier.$error &&
                      $v.newInscriprion.selectedDossier.$dirty,
                  }"
                ></b-form-input>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newInscriprion.selectedDossier.$dirty"
                >
                  {{
                    !$v.newInscriprion.selectedDossier.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
              </div>
              <div v-else>
                <v-select
                  append-to-body
                  id="mySelect"
                  :options="dossierlist"
                  label="text"
                  :disabled="
                    data.item.id &&
                    !isAdmin &&
                    !isSuper &&
                    !isManager &&
                    !isGouvernance
                  "
                  :reduce="(respo) => respo.value"
                  placeholder="Dossier"
                  v-model="newInscriprion.selectedDossier"
                  class="font-weight-normal"
                  style="padding-right: 0 !important"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.newInscriprion.selectedDossier.$error &&
                        $v.newInscriprion.selectedDossier.$dirty,
                      ' form-control': true,
                    },
                  }"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newInscriprion.selectedDossier.$error &&
                      $v.newInscriprion.selectedDossier.$dirty,
                  }"
                >
                  <template #no-options> Liste vide</template>
                </v-select>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newInscriprion.selectedDossier.$dirty"
                >
                  {{
                    !$v.newInscriprion.selectedDossier.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:cell(invite)="data">
          <div class="text-center">
            <b-badge
              v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
              pill
              @click="$emit('showMember', data.item)"
              style="
                cursor: pointer;
                background-color: #adb5bd;
                border-color: #adb5bd;
              "
              >{{ data.value }}
            </b-badge>
            <multiselect
              v-else
              label="text"
              track-by="value"
              multiple
              :options="responsables"
              v-model="selectedInvité"
              :allowClear="true"
              class="font-weight-normal"
              required
            ></multiselect>
          </div>
        </template>
        <template v-slot:cell(decision)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') && editedItem == null"
            >{{ data.value ? data.value.decision : "-" }}</span
          >
          <div v-else style="width: 150px">
            <v-select
              v-if="!items.find((contributer) => contributer.Modify == true)"
              placeholder="Décision"
              disabled
              v-model="decision"
            ></v-select>
            <div v-else>
              <v-select
                :disabled="
                  data.item.id && !isAdmin && !isSuper && !isGouvernance
                "
                append-to-body
                :options="[
                  'En attente',
                  'Recommandations',
                  'Favorable',
                  'Ajourné',
                  'Aucune',
                ]"
                label="text"
                :reduce="(respo) => respo.text"
                placeholder="Décision"
                v-model="decision"
                class="font-weight-normal"
                style="padding-right: 0 !important"
              >
                <template #no-options> Liste vide</template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.decision.$dirty"
              >
                {{ !$v.decision.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <table-actions
            :actions="actions"
            :editMode="data.item.hasOwnProperty('editMode')"
            @confirmItem="confirmItem(data.item)"
            @cancelItem="cancelItem(data.item)"
            @deleteItem="$emit('deleteItem', data.item.id)"
            @editItem="editItem(data.item)"
          ></table-actions>
        </template>
      </b-table>
    </div>
    <br />
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { required } from "vuelidate/lib/validators";
import { IMG_URL } from "@/helpers/services/api";
import Swal from "sweetalert2";
import { datePickerFormat } from "@/helpers/DatePickerFormat";
import CustomAddButton from "@/components/custom/CustomAddButton";

export default {
  components: { TableActions, TableResponsable, CustomAddButton },

  props: {
    legalEntity: Object,
    items: {
      type: Array,
      default: () => [],
    },
    session: String,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    IMG_URL,
    newInscriprion: {
      objetPassage: "",
      date: "",
      temps: "",
      status: "",
      rangPassage: "",
      selectedResponsable: "",
      selectedDossier: "",
    },

    file: null,
    decision: "",
    fields: [
      { key: "type", label: "Type dossier", sortable: true },
      { key: "dossier", label: "Dossier", sortable: true },
      { key: "objetPassage", label: "Objet passage", sortable: true },
      { key: "user", label: "Présentateur", sortable: true },
      { key: "createdAt", label: "Date inscription", sortable: true },
      { key: "status", label: "Statut inscription", sortable: true },
      { key: "rangPassage", label: "Rang passage", sortable: true },
      { key: "temps", label: "Temps", sortable: true },
      { key: "livrable", label: "Livrable", sortable: true },
      { key: "decision", label: "Décision", sortable: true },

      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },
        sortable: false,
      },
    ],
    actions: ["edit", "delete"],
    sortBy: "name",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    selecteddata: [],
    dossierlist: [],
    selectedInvité: null,

    selectedRole: "",
    editedItem: null,
    type: "",
    newContributer: {
      user: null,
      dossier: null,
      role: "",
      actions: ["delete"],
    },

    i: 0,
  }),
  watch: {
    items() {
      this.selecteddata = this.items;
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("dossier/fetchAllDossier");
    this.$store.dispatch("role/fetchAllContributorRoles");
    this.$store.dispatch("decision/fetchDecisions");
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("cadre/fetchAllCadres");
    this.$store.dispatch("composant/fetchAllComposants");

    this.selecteddata = this.items;
    // this.loading = false;
  },
  validations: {
    newInscriprion: {
      selectedResponsable: {
        required,
      },
      selectedDossier: {
        required,
      },
      type: {
        required,
      },
      objetPassage: {
        required,
      },
      status: {
        required,
      },

      rangPassage: {
        required,
      },
      temps: {
        required,
      },
    },
    decision: {
      required,
    },
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("role", ["CONTRIBUTOR_ROLES"]),
    ...mapGetters("dossier", ["DOSSIER"]),
    ...mapGetters("decision", ["DECISIONS"]),
    ...mapGetters("fdr", ["FDRS"]),
    ...mapGetters("initiative", ["INITIATIVES"]),
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("composant", ["COMPOSANTS"]),
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    ...mapGetters("manage", ["INSCRIPTION"]),
    ...mapGetters(["isAdmin", "isSuper", "isManager", "isGouvernance"]),

    datatable() {
      return this.selecteddata.inscriptions
        ? this.selecteddata.inscriptions.map((item) => ({
            ...item,
            invitelist: item.invite ? item.invite : "",
            dossier: item.dossier ? item.dossier.name : "-",
            dossierid: item.dossier ? item.dossier.id : "",
            invite: item.invite ? item.invite.users.length : 0,
          }))
        : [];
    },

    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        value: `api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
      return data;
    },
    decisionList() {
      var data = this.DECISIONS.map((decision) => ({
        value: decision["@id"],
        text: decision.decision,
      }));
      return data;
    },
    // dossier() {
    //   var data = this.DOSSIER.map((dossier) => ({
    //     value: dossier.id,
    //     text: dossier.name,
    //   }));
    //   return data;
    // },
    role() {
      return this.CONTRIBUTOR_ROLES;
    },
    rows() {
      return this.items ? this.items.length : 0;
    },
    rangePassagesList() {
      return Array.from({ length: 10 }, (_, i) => i + 1).filter(
        (item) =>
          !this.items.find((inscription) =>
            inscription.rangPassage ? inscription.rangPassage === item : false
          )
      );
    },
  },
  methods: {
    addContributerInputs() {
      if (
        this.isAdmin ||
        this.isSuper ||
        this.isManager ||
        this.isGouvernance
      ) {
        var data = this.items.some((contribute) => contribute.editMode);
        if (data === false) {
          this.decision = "En attente";
          this.items.unshift({
            editMode: true,
          });
        }
      }
    },
    changedossier() {
      if (!this.newInscriprion.type) {
        this.newInscriprion.type = "";
        this.newInscriprion.selectedDossier = "";
      }
      if (this.newInscriprion.type == "FdR") {
        this.selectedDossier = null;

        this.dossierlist = this.FDRS.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Initiative") {
        this.selectedDossier = null;

        this.dossierlist = this.INITIATIVES.map((data) => ({
          text: data.libelle,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Cadre") {
        this.selectedDossier = null;

        this.dossierlist = this.CADRE_ENTREPRISES.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Composant technique") {
        this.selectedDossier = null;

        this.dossierlist = this.COMPOSANTS.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Application") {
        this.selectedDossier = null;

        this.dossierlist = this.APPLICATIONS.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else {
        this.dossierlist = [];
      }
    },
    getdossier() {
      if (this.newInscriprion.type == "FdR") {
        this.dossierlist = this.FDRS.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Initiative") {
        this.dossierlist = this.INITIATIVES.map((data) => ({
          text: data.libelle,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Cadre") {
        this.dossierlist = this.CADRE_ENTREPRISES.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Composant technique") {
        this.dossierlist = this.COMPOSANTS.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else if (this.newInscriprion.type == "Application") {
        this.dossierlist = this.APPLICATIONS.map((data) => ({
          text: data.name,
          value: data["@id"],
        }));
      } else {
        this.dossierlist = [];
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    confirmItem: function (insc) {
      this.$v.newInscriprion.$touch();
      if (!this.$v.newInscriprion.$invalid) {
        var datax = this.items.find(
          (contributer) => contributer.Modify == true
        );
        if (datax == null) {
          var data = {
            sessionComite: `api/gouvernance/sessions/${this.$route.params.id}`,
            objetPassage: this.newInscriprion.objetPassage,
            rangPassage: parseInt(this.newInscriprion.rangPassage),
            status: this.newInscriprion.status,
            temps: this.newInscriprion.temps,
            user: this.newInscriprion.selectedResponsable,
          };
          this.$store.dispatch("manage/createInscription", data).then(() => {
            let formData = new FormData();
            this.file ? formData.append("file", this.file) : "",
              formData.append("inscription", this.INSCRIPTION["@id"]);
            formData.append(
              "name",
              this.file ? this.file.name : this.newInscriprion.selectedDossier
            );
            if (this.newInscriprion.type == "FdR")
              formData.append(
                "feuileroute",
                this.newInscriprion.selectedDossier
              );
            else if (this.newInscriprion.type == "Initiative")
              formData.append(
                "initiative",
                this.newInscriprion.selectedDossier
              );
            else if (this.newInscriprion.type == "Cadre")
              formData.append("cadre", this.newInscriprion.selectedDossier);
            else if (this.newInscriprion.type == "Composant technique")
              formData.append("composant", this.newInscriprion.selectedDossier);
            else if (this.newInscriprion.type == "Application")
              formData.append(
                "application",
                this.newInscriprion.selectedDossier
              );

            this.$store.dispatch("dossier/createDossier", formData).then(() => {
              Swal.fire("L'inscription est bien créée !", "", "success");
              this.$store
                .dispatch("manage/fetchSession", this.$route.params.id)
                .then(() => {});
              this.newInscriprion.selectedResponsable = "";
              this.newInscriprion.selectedDossier = "";
              this.newInscriprion.type = "";
              this.decision = "";
              this.newInscriprion.status = "";
              this.newInscriprion.date = "";
              this.newInscriprion.rangPassage = "";
              this.newInscriprion.objetPassage = "";
              this.newInscriprion.temps = "";
            });
          });
          this.$v.newInscriprion.$reset();
        } else {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            if (this.decision !== "") {
              var decision = {
                id: insc.decision.id,
                decision: this.decision,
              };
              this.$store
                .dispatch("decision/updateDecision", decision)
                .then(() => {
                  this.$store.dispatch(
                    "manage/fetchSession",
                    this.$route.params.id
                  );
                });
            }
            var inscription = {
              sessionComite: `api/gouvernance/sessions/${this.$route.params.id}`,
              id: insc.id,
              objetPassage: this.newInscriprion.objetPassage,
              rangPassage: parseInt(this.newInscriprion.rangPassage),
              status: this.newInscriprion.status,
              temps: this.newInscriprion.temps,
              user: this.newInscriprion.selectedResponsable,
            };
            this.$store.dispatch("manage/updateInscription", inscription);
            Swal.fire("L'inscription est mise à jour !", "", "success");
            let dossierx;
            if (this.newInscriprion.type == "Initiative") {
              dossierx = {
                id: insc.livrable.id,
                name: this.file
                  ? this.file.name
                  : this.newInscriprion.selectedDossier,
                initiative: this.newInscriprion.selectedDossier,
                feuileroute: null,
                cadre: null,
                application: null,
                composant: null,
              };
            } else if (this.newInscriprion.type == "FdR") {
              dossierx = {
                id: insc.livrable.id,
                name: this.file
                  ? this.file.name
                  : this.newInscriprion.selectedDossier,
                initiative: null,
                cadre: null,
                application: null,
                composant: null,
                feuileroute: this.newInscriprion.selectedDossier,
              };
            } else if (this.newInscriprion.type == "Cadre") {
              dossierx = {
                id: insc.livrable.id,
                name: this.file
                  ? this.file.name
                  : this.newInscriprion.selectedDossier,
                initiative: null,
                feuileroute: null,
                application: null,
                composant: null,
                cadre: this.newInscriprion.selectedDossier,
              };
            } else if (this.newInscriprion.type == "Application") {
              dossierx = {
                id: insc.livrable.id,
                name: this.file
                  ? this.file.name
                  : this.newInscriprion.selectedDossier,
                initiative: null,
                feuileroute: null,
                cadre: null,
                composant: null,
                application: this.newInscriprion.selectedDossier,
              };
            } else if (this.newInscriprion.type == "Composant technique") {
              dossierx = {
                id: insc.livrable.id,
                name: this.file
                  ? this.file.name
                  : this.newInscriprion.selectedDossier,
                initiative: null,
                feuileroute: null,
                cadre: null,
                composant: this.newInscriprion.selectedDossier,
                application: null,
              };
            }
            this.$store.dispatch("dossier/updateDossier", dossierx).then(() => {
              this.$store
                .dispatch("manage/fetchSession", this.$route.params.id)
                .then(() => {
                  this.newInscriprion.selectedResponsable = "";
                  this.newInscriprion.selectedDossier = "";
                  this.newInscriprion.type = "";
                  this.decision = "";
                  this.newInscriprion.status = "";
                  this.newInscriprion.date = "";
                  this.newInscriprion.rangPassage = "";
                  this.newInscriprion.objetPassage = "";
                  this.newInscriprion.temps = "";
                });
            });
            if (this.file) {
              var formData = new FormData();
              formData.append("file", this.file);
              this.$store
                .dispatch("dossier/createDossierfile", {
                  id: insc.livrable.id,
                  file: formData,
                })
                .then(() => {
                  this.$store
                    .dispatch("manage/fetchSession", this.$route.params.id)
                    .then(() => {
                      this.newInscriprion.selectedResponsable = "";
                      this.newInscriprion.selectedDossier = "";
                      this.newInscriprion.type = "";
                      this.decision = "";
                      this.newInscriprion.status = "";
                      this.newInscriprion.date = "";
                      this.newInscriprion.rangPassage = "";
                      this.newInscriprion.objetPassage = "";
                      this.newInscriprion.temps = "";
                    });
                });
            }
            this.$v.$reset();
          }
        }
      }
    },
    datePickerFormat,
    editItem(data) {
      this.$store
        .dispatch("manage/fetchSession", this.$route.params.id)
        .then(() => {
          this.newInscriprion.selectedResponsable = `api/users/${data.user.id}`;
          this.newInscriprion.selectedDossier = data.dossierx
            ? data.dossierx.feuileroute
              ? data.dossierx.feuileroute["@id"]
              : data.dossierx.initiative
              ? data.dossierx.initiative["@id"]
              : data.dossierx.composant
              ? data.dossierx.composant["@id"]
              : data.dossierx.application
              ? data.dossierx.application["@id"]
              : data.dossierx.cadre
              ? data.dossierx.cadre["@id"]
              : data.dossierx.name
            : "";
          this.newInscriprion.type = data.type;
          this.newInscriprion.temps = data.temps;
          this.decision = data.decision.decision;
          this.newInscriprion.status = data.status;
          this.newInscriprion.date = datePickerFormat(data.createdAt);
          this.newInscriprion.rangPassage = data.rangPassage;
          this.newInscriprion.objetPassage = data.objetPassage;
          this.getdossier();
          this.items = this.items.map((contributer) =>
            contributer.id === data.id
              ? { ...contributer, editMode: true, Modify: true }
              : contributer
          );
        });
    },
    cancelItem: function (item) {
      if (
        this.newInscriprion.selectedResponsable == "" &&
        this.newInscriprion.selectedDossier == "" &&
        (!item.id ? true : this.decision == "") &&
        this.newInscriprion.status == "" &&
        this.newInscriprion.date == "" &&
        this.newInscriprion.rangPassage == "" &&
        this.newInscriprion.objetPassage == "" &&
        this.newInscriprion.temps == ""
      ) {
        var data = this.items.find((contributer) => contributer.Modify == true);
        if (data == null) {
          this.items.splice(0, 1);
        } else {
          this.$store.dispatch("manage/fetchSession", this.$route.params.id);
        }
      } else {
        this.newInscriprion.selectedResponsable = "";
        this.newInscriprion.selectedDossier = "";
        this.newInscriprion.type = "";
        this.decision = item.id ? "" : "En attente";
        this.newInscriprion.status = "";
        this.newInscriprion.date = "";
        this.newInscriprion.rangPassage = "";
        this.newInscriprion.objetPassage = "";
        this.newInscriprion.temps = "";
      }
    },
  },
  mounted() {
    this.selecteddata = this.items;
  },
};
</script>
<style>
#mySelect .v-select .dropdown-toggle {
  border: none;
  animation-fill-mode: none;
  z-index: 50212142545 !important;
}

.multiselect__tag ~ .multiselect__loading-transition ~ .multiselect__input {
  width: 1px;
  color: transparent;
}
</style>

 